<script setup lang="ts">
import type { Key } from 'ant-design-vue/es/_util/type'
import type { TablePaginationConfig } from 'ant-design-vue'
import { UserTypeMap } from '@/constant/user'
import { queryUsersApi } from '@/api/user'

const emit = defineEmits(['confirm'])
const userStore = useUserStore()
const open = defineModel<boolean>('open')
const param = ref({ current: 1, size: 20 } as UserParam)

const search = ref<TableSearchType[]>([
  { label: '用户名', name: 'loginName', placeholder: '请输入用户名', required: false, type: 'input' },
  { label: '控制权限', name: 'authControl', placeholder: '请选择', required: false, type: 'select', options: [
    { label: '可控制智能设备', value: 'true' },
    { label: '不可控制智能设备', value: 'false' },
  ] },
  { label: '用户类型', name: 'userType', placeholder: '请选择', required: false, type: 'select', options: (() => {
    const opts = [
      { label: '经营管理员', value: 'MANAGER' },
      { label: '普通用户', value: 'RUNTIME' },
    ]
    if (userStore.isRole('DEPLOYER')) {
      opts.unshift({ label: '实施工程师', value: 'DEPLOYER' })
    }
    return opts
  })() },
])
const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '用户ID', width: 80, dataIndex: 'id' },
    { title: '用户名', dataIndex: 'loginName' },
    {
      title: '用户类型',
      dataIndex: 'userType',
      customRender({ record }) {
        return UserTypeMap.get(record.userType)
      },
    },
    {
      title: '设备控制权限',
      dataIndex: 'authControl',
      customRender({ record }) {
        return record.authControl ? '有' : '无'
      },
    },
  ],
  config: { total: 0, loading: false, isBorder: false, isSerialNo: true, isSelection: true, isOperate: true },
  rowSelection: {
    type: 'radio',
    selectedRowKeys: [],
    onChange(selectedRowKeys: Key[], _selectedRows: any[]) {
      if (state.rowSelection) {
        state.rowSelection.selectedRowKeys = selectedRowKeys
      }
    },
  },
  pagination: {
    position: ['bottomLeft'],
    pageSize: 20,
    pageSizeOptions: ['20', '30', '40', '50'],
    total: 0,
    current: 1,
    showTotal: total => `共${total}条`,
  },
  rowKey: 'id',
})

watchEffect(async () => {
  if (open.value === true) {
    await refresh()
  }
})

async function onSubmit() {
  if (state.rowSelection?.selectedRowKeys && state.rowSelection.selectedRowKeys.length > 0) {
    emit('confirm', state.rowSelection.selectedRowKeys[0])
    open.value = false
  }
}

async function refresh() {
  state.config.loading = true
  const res = await queryUsersApi(param.value)
  const { records, current, size, total } = res
  state.data = records
  const pagination = state.pagination as TablePaginationConfig
  pagination.current = current
  pagination.pageSize = size
  pagination.total = total

  setTimeout(() => {
    state.config.loading = false
  }, 500)
}

async function onTablePageChange(current: number, pageSize: number) {
  param.value.current = current
  param.value.size = pageSize;
  (state.pagination as TablePaginationConfig).current = current;
  (state.pagination as TablePaginationConfig).pageSize = pageSize
  await refresh()
}

async function onSearch(form: any) {
  param.value = { ...form, current: 1, size: (state.pagination as TablePaginationConfig).pageSize ?? 20 }
  await refresh()
}
</script>

<template>
  <a-modal v-model:open="open" title="用户列表" width="60%" :centered="true" @ok="onSubmit">
    <div h-600px flex flex-col>
      <search-form :search="search" mb-10px @search="onSearch" />
      <base-table v-bind="state" @page-change="onTablePageChange" />
    </div>
  </a-modal>
</template>
